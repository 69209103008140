body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-webkit-scrollbar {
  width: 4px; /* Grosor inicial */
  height: 6PX;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #80808080; /* Color de la barra */
  opacity: 20%;
  border-radius: 10px;
  transition: all 0.3s ease; /* Transición suave */
}

/* Scrollbar cuando se hace hover */
::-webkit-scrollbar-thumb:hover {
  width: 6px; /* Cambia el grosor al hacer hover */
}